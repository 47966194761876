<template>
  <div class="contentbody" style="" v-loading='store._state.data.app.Loading'  element-loading-text="正在加载...">
    <nodata v-if="wardsList.data&&wardsList.data.awards.length<1&&!store._state.data.app.Loading" :code='wardsList.code' :list='wardsList.data&&wardsList.data.awards'/>
    <div v-else-if='!store._state.data.app.Loading'>
       <div class="tip" style="text-align: center;color: #4A4A4A;font-size:16px;margin:50px 0;line-height: 28px;">{{t('base.menu.yearCon') }}</div>
      <div v-for="(items, i) in wardsList.data.awards" :key="i">
        <div class="type-img" style="text-align: center">
          <img v-if="language == 'cn' " :src="require('@/assets/images/type-icon' + i + '.svg')" alt="" />
          <img v-else :src="require('@/assets/images/type-icon-en' + i + '.svg')" alt="" />
        </div>
        <ul>
          <template v-if="items.list.length">
          <li v-for="item in items.list" :key="item.id" @click="viewClick(item)">
            <div class="img-div"><img :src="item.avatar" alt="" /></div>
            <div class="title-div">
              <div class="name">
                {{ language == "cn" ? item.cn_name||item.en_name : item.en_name }}
              </div>
              <div class="describe">
                {{ language == "cn" ? item.cn_position : item.en_position }}
              </div>
            </div>
            <div class="hover">
              <div style="margin-top: 49%;">
                <div class="name">
                  {{ language == "cn" ? item.cn_name||item.en_name : item.en_name }}
                </div>
                <div class="describe">
                  {{ language == "cn" ? item.cn_position : item.en_position }}
                </div>
              </div>
            </div>
          </li>
          </template>
         <div v-else style="width: 100%;height:80px">
            <!-- <nodata  :code='wardsList.code' :list='wardsList.data&&wardsList.data.awards'/> -->
         </div>
        </ul>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="" width="1200px" height='500' >
      <div class="" style="display: flex;">
        <!-- <el-row :gutter="0">
          <el-col :span="8"> -->
           <div class="left" style="margin-left: 10px;">
              <div class="avatar">
              <img :src="selItem.value.avatar" alt="" />
            </div>
           </div>
          <!-- </el-col>
          <el-col :span="16" class="content"> -->
            <div class="content"> 
              <div class="title" style="margin-bottom:8px">{{language == "cn"?selItem.value.cn_name:selItem.value.en_name}}</div>
              <div class="title">{{language == "cn"?selItem.value.cn_position:selItem.value.en_position}}</div>
              <div class="reason" v-if='language == "cn"' v-html="selItem.value.cn_reason"></div>
              <div class="reason" v-else v-html="selItem.value.en_reason"></div>
            </div>
           
          <!-- </el-col> -->
        <!-- </el-row> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { defineComponent, ref, computed, watch, reactive } from "vue";
import { useGetters } from "@/store/use.js";
import { useRouter, onBeforeRouteUpdate } from "vue-router";
import store from "@/store";
import nodata from './nodata.vue'
import { useI18n } from 'vue-i18n'
import { ElLoading } from 'element-plus'
export default defineComponent({
  name: "入选者",
  components:{
    nodata
  },
  setup() {
    const { t, locale } = useI18n()
    const router = useRouter();
    const getters = useGetters(["language", "wardsList"]);
    const language = getters.language;
    const query = router.currentRoute.value.query,
      dialogVisible = ref(false),
      selItem = reactive({});
    store.dispatch("app/getAwards", query.title)
    const wardsList = getters.wardsList;
    const viewClick = (item) => {
      dialogVisible.value = true;
      selItem.value = item;
    };
    return {
      t,
      wardsList,
      language,
      dialogVisible,
      selItem,
      viewClick,
      store
    };
  },
});
</script>

<style lang="scss" scoped>
.contentbody{
  margin-top: 50px; margin: 0 auto;
  width: 1200px;
  min-height: 200px;
ul {
  margin: 40px auto 20px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  li {
    // width: 16.666%;
    // width: 20%;
    margin-right: 50px;
    // display: inline-block;
    text-align: center;
    margin-bottom: 50px;
    cursor: pointer;
    position: relative;
    .img-div {
      height: 200px;
      width: 200px;
      // width: 5rem;
      // height: 5rem;
      background: rgba(96, 96, 96, 0.15);
      border-radius: 100%;
      position: relative;
      margin: auto;
      z-index: 999;
      img {
        width: 180px;
        height: 180px;
        border-radius: 100%;
        object-fit: cover;
        position: absolute;
        left: 5%;
        top: 5%;
      }
    }
    .name {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #222222;
      line-height: 28px;
    }
    .describe {
      min-height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 22px;
      margin: 6px 0;
    }
    .title-div {
      width: 176px;
      margin: 0 auto;
    }
    .name {
        margin-top: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    .describe {
        height: 47px;
        width: 176px;
        display: -webkit-box;
        // word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    .hover {
      width: 240px;
      height: 210px;
      background: #FFFFFF;
      box-shadow: 0px 24px 50px 0px rgba(207, 190, 195, 0.5);
      border-radius: 16px;
      display: none;
      overflow: hidden;
      position: absolute;
      top: 32%;
      left: -10%;
      .name {
        min-height: 28px;
        margin-bottom: 6px;
      }
      .describe{margin: 0 auto;}
    }
  }
  li:nth-child(5n){margin-right: 0px;}
  li:hover {
    .hover {
      display: block;
    }
    // .title-div{display: ;}
  }
}
}
.avatar {
  height: 400px;
  width: 400px;
  background: rgba(96, 96, 96, 0.15);
  border-radius: 100%;
  position: relative;
  margin: auto;
  z-index: 999;
  img {
    width: 360px;
    height: 360px;
    border-radius: 100%;
    object-fit: cover;
    position: absolute;
    left: 5%;
    top: 5%;
  }
}
.content{
   font-family: PingFangSC-Regular, PingFang SC;
   padding:0 20px 0 40px;
  .title{
    font-size: 32px;
    font-weight: 600;
    color: #ED3524;
    line-height: 44px;
  }
  .reason{
    margin-top: 24px;
    font-size: 16px;
    font-weight: 400;
    color: #4A4A4A;
    line-height: 28px;
  }
}
:deep(.el-dialog){
  .el-dialog__header{
    .el-icon{
        font-size: 30px !important;
        color: #000 !important;
    }
  }
    .el-dialog__body{word-break: break-word;padding: 20px 20px 50px;}
  }
  @media screen and (min-width: 375px) and (max-width: 750px){
    .contentbody{
      width: 100%;
      ul{
        width: 100%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
        li{width: 50%;
      
        margin: 0px;
        overflow: hidden;
       
        height: 320px;
        .img-div{
              width: 170px;
              height: 170px;
              img{width: 90%;height: 90%;}
        }
        .title-div,.name,.describe{width: 100%;}
        .describe{height:44px}
        .title-div{height: calc(100% - 185px);padding: 0 10px;}
        }
        li:nth-child(2n){margin-right: 0px;}
        li:hover{.hover{display: none;}}
      }
      .type-img img{width: 80%; margin: 0 auto; height: 70px;}
    }
     :deep(.el-overlay-dialog){
      .el-dialog{
        --el-dialog-width:95% !important;
        // width: 90% !important;
        .el-dialog__body>div{display: block !important;}
        .left,.content{max-width: 100%;flex: 100%;margin-left: 0px !important;padding: 0 !important;}
        .avatar{width: 310px;height: 310px;
        img{
          width: 90%;
          height: 90%;
          left: 5%;
          top: 5%;
        }
        }
      }
    }
    
  }

</style>
