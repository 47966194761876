<template>
  <div class="annualWinner">
      <div class="banner-box" v-if="getters.activityBannerList.value&&getters.activityBannerList.value.image">
            <el-carousel trigger="click" :autoplay='true' height="400px" :interval='4000'>
                <el-carousel-item v-for="(item,i) in getters.activityBannerList.value.image.split(',')" :key="i">
                   <img :src="item" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
      <div class="maintitle">
        <div v-for="item in tabList"
          :key="item.ind"
          :class="item.ind === store._state.data.app.yearType && 'active'"
          @click="() => tabClick(item.ind)"
          ><span>{{ t('base.menu.'+item.title) }}</span></div>
    </div>
    <selected-person v-if="store._state.data.app.yearType === 1"/>
    <Judges v-else/>
  </div>
</template>
<script>
import selectedPerson from './components/selectedPerson.vue';
import Judges from './components/Judges.vue';
import { defineComponent, ref, reactive } from 'vue'
import {useStore} from 'vuex'
import { useI18n } from 'vue-i18n'
import store from '@/store'
import { useRouter, onBeforeRouteUpdate } from 'vue-router'
import {useGetters} from '@/store/use.js'
  export default defineComponent({
    components: {
    selectedPerson,
    Judges,
  },
    setup() {
       const { t, locale } = useI18n()
      var store =useStore();
      const getters=useGetters(['language','activityBannerList'])
      const tabList = ref([
        { name: '入选者',title:'selected', ind: 1 },
        { name: '评委',title:'Judges', ind: 2 }
      ])
       const activeInd = ref(1);
        const router = useRouter();
        const query = router.currentRoute.value.query;
       store.dispatch('app/getActivityBanner', query.title)
       const tabClick = (ind) => {
        activeInd.value = ind;
        store._state.data.app.yearType=ind;
        store._state.data.app.wardsList=[];
        store._state.data.app.judgesList=[];
      }
      return {
        t,
        tabList,
        activeInd,
        tabClick,
        store,
        getters
        
      }
    }
  })
</script>
<style lang="scss" scoped>
  .annualWinner{
       width: 100%;
      // padding-bottom: 0px;
      min-height: calc(100vh - 300px);
      :deep(.banner-box){
         .el-carousel__indicators{display:block !important;}
            width: 100%;
            position: relative;
            text-align: center;
            img{
                width: 100%;
                height: 100%;
            }
            .title{
                width: 100%;
                padding: 0 16%;
                height: 70px;
                font-size: 50px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 70px;
                position: absolute;
                top: 50%;
                margin-top: -70px;
                text-align: center;
                // margin-left: ;
            }
        }
         .maintitle {
          display: flex;
          justify-content: center;
          margin: 80px 0 70px;
          > div {
            padding: 0 48px;
            // border-right: 1px solid #eeeeee;
            font-size: 40px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #222222;
            cursor: pointer;
          }
          > div:nth-last-of-type(1) {
            border-right: none;
          }
          span:hover{color: #ED3524;}
          .active span {
            color: #ED3524;
            position: relative;
            border-bottom:5px solid #ED3524;
            padding-bottom: 15px;
          }
          // .active:after {
          //   content: '';
          //   width: 60px;
          //   height: 5px;
          //   background: #e34c3e;
          //   border-radius: 4px;
          //   position: absolute;
          //   bottom: -15px;
          //   left: 50%;
          //   margin-left: -30px;
          // }
    }
  }
    @media screen and (min-width: 375px) and (max-width: 750px){
    .annualWinner{
      .maintitle {
          > div {
            padding: 0 15px;
            font-size: 24px;
          }
          }
          :deep(.banner-box){
            .el-carousel__container{height: 120px !important;}
            .el-carousel__item {
              img{height: auto;}
            }
            .el-carousel__indicators{display: none !important;}
          }
          
    }
    
  }
</style>
