<template>
  <div class="contentbody" style="" v-loading='store._state.data.app.Loading'  element-loading-text="正在加载...">
    <nodata v-if="judgesList.data&&judgesList.data.length<1&&!store._state.data.app.Loading" :code='judgesList.code' :list='judgesList.data'/>
    <div v-else-if='!store._state.data.app.Loading'>
      <div class="tip" style="text-align: center;color: #4A4A4A;font-size:16px;margin:50px 0;line-height: 28px;">{{t('base.menu.yearCon2') }}</div>
      <ul>
        <li v-for="item in judgesList.data" :key="item.id">
          <div class="default">
          <div style="width:210px;height:200px;overflow: hidden;">
              <img :src="item.user_avatar" alt="" />
          </div>
            <div class="name" style="overflow: hidden;white-space: nowrap;text-overflow :ellipsis;"> {{ language == "cn" ? item.cn_username : item.en_username }}</div>
          </div>
          <div class="active">
              <img :src="item.user_avatar" alt="" />
              <div class="active-title">
                  <div  style="bottom: 0px;position: absolute;width:100%">
                      <div class="name">{{ language == "cn" ? item.cn_username||item.en_username : item.en_username }}</div>
                      <div class="describe">{{ language == "cn" ? item.cn_user_job : item.en_user_job }}</div>
                  </div>
              </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed, watch, reactive } from "vue";
import { useGetters } from "@/store/use.js";
import { useRouter, onBeforeRouteUpdate } from "vue-router";
import store from "@/store";
import { useStore } from "vuex";
import nodata from './nodata.vue'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  name: "评委",
   components:{
    nodata
  },
  setup() {
    const { t, locale } = useI18n()
    const router = useRouter();
    const getters = useGetters(["language", "judgesList"]);
    const language = getters.language;
    const query = router.currentRoute.value.query;
    store.dispatch("app/getAwards", query.title);
    const judgesList = getters.judgesList;
    return {
      t,
      judgesList,
      language,
      store
    };
  },
});
</script>
<style lang="scss" scoped>
.contentbody{
  margin-top: 50px; width: 1200px; margin: 0 auto;min-height: 200px;
ul {
  margin: 50px auto;
  li {
    width: 210px;
    height: 272px;
    display: inline-block;
    position: relative;
    margin: 0 18.5px 40px;
    border-radius: 6px;
    border: 1px solid #EEEEEE;
   overflow: hidden;
   .name{
     font-size: 20px;color: #222222;margin: 20px auto 0;width:195px;
    line-height: 28px;font-weight: 600;text-align: center;
   }
    .default {
      height: 100%;
      width: 100%;
      line-height: 28px;
      img {
        width: 100%;
        // height: 72%;
        // object-fit:contain;
      }
    }
    .active {
       line-height: 26px;
      display: none;
      width: 210px;
      min-height: 272px;
      position: absolute;
      bottom: 0px;
      z-index: 99;
      .active-title {
       position: absolute;
        width: 100%;
        color: #fff;
        font-size: 14px;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 6px;
      }
      img{
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            position: absolute;
            z-index: -1;
            left: 0px;
            border-radius: 6px;
      }
      .name {
        color: #fff;
        margin-bottom: 10px;
      }
      .describe{
          width: 186px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26px;
          margin: 0 auto;
          padding-bottom: 14px;
      }
    }
  }
   li:nth-child(5n+1){
      margin-left: 0px;
    }
     li:nth-child(5n){
      margin-right: 0px;
    }
    @keyframes active_move
    {
        from {bottom:-100px;}
        to {bottom:0px;}
    }
    @-webkit-keyframes active_move /* Safari and Chrome */
    {
       from {bottom:-100px;}
        to {bottom:0px;}
    }
  li:hover {
    .active {
      display: block;
       animation-duration: .5s;
        animation-fill-mode: both;
        animation-name: active_move;
    }
  }
}
}
@media screen and (min-width: 375px) and (max-width: 750px){
    .contentbody{
      width: 100%;
      ul {
        // text-align: center;
        padding: 0 10px;
        li{
        width: 47.77%;
        margin: 0 15px 20px 0 !important;
        height: 260px;
        .default{
          >div{width: 100% !important;}
         
        }
        .active{width: 100%;height: 100%;}
         .name,.describe{width: 99% !important;}
        .img-div {width: 180px;height: 180px;.name{font-size: 18px;}}
        }
         li:nth-child(2n){margin-right: 0px !important;}
      }
    }
  }
</style>
